import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const dicePool = [{ num: 0, hold: false }, { num: 0, hold: false }, { num: 0, hold: false }, { num: 0, hold: false }, { num: 0, hold: false }];

const maxRollCount = 3;
const maxTurn = 12;
const bonusLimit = 63;
const bonusScore = 35;
const rollText = 'Roll!';
const cantRollText = 'Cannot Roll!';

const scoreBoard = [
  { id: 'ones', value: 0, applied: false },
  { id: 'twos', value: 0, applied: false },
  { id: 'threes', value: 0, applied: false },
  { id: 'fours', value: 0, applied: false },
  { id: 'fives', value: 0, applied: false },
  { id: 'sixes', value: 0, applied: false },
  { id: 'bonus', value: 0, applied: false },
  { id: 'choice', value: 0, applied: false },
  { id: 'fourOfAKind', value: 0, applied: false },
  { id: 'fullHouse', value: 0, applied: false },
  { id: 'littleStraight', value: 0, applied: false },
  { id: 'bigStraight', value: 0, applied: false },
  { id: 'yacht', value: 0, applied: false },
  { id: 'total', value: 0, applied: false }];
// const scoreName = ['ones', 'twos', 'threes', 'fours', 'fives', 'sixes', 'bonus', 'choice', 'fourOfAKind', 'fullHouse', 'littleStraight', 'bigStraight', 'yacht', 'total'];

function RollButton(props) {
  if (props.remainRollCount !== 0 && props.diceText === cantRollText) {
    return cantRollText;
  } else {
    return <button onClick={props.onClick}>{props.diceText}</button>;
  }
}

function ScoreButton(props) {
  if (props.myScores[props.index].applied) {
    return props.myScores[props.index].value;
  } else {
    return <button onClick={() => props.updateScore(props.index)}>{props.myScores[props.index].value}</button>
  }
}

function EachScore(props) {
  const arr = [];
  for (let i = 0; i < props.myScores.length; ++i) {
    if (props.myScores[i].id === 'bonus' || props.myScores[i].id === 'total') {
      arr.push(<tr id={i}>
        <th>{props.myScores[i].id}</th>
        <th>{props.myScores[i].value}</th>
        <th>{0/*props.opScores[i].value*/}</th>
      </tr>);
    } else if (props.remainRollCount === maxRollCount) {
      arr.push(<tr id={i}>
        <td>{props.myScores[i].id}</td>
        <td>{props.myScores[i].value}</td>
        <td>{0/*props.opScores[i].value*/}</td>
      </tr>);
    } else {
      arr.push(<tr id={i}>
        <td>{props.myScores[i].id}</td>
        <td><ScoreButton myScores={props.myScores} index={i} updateScore={props.updateScore} /></td>
        <td>{0/*props.opScores[i].value*/}</td>
      </tr>);
    }
  }

  return arr;
}

function Table(props) {
  return (
    <table>
      <thead>
        <tr>
          <th>Category</th>
          <th>Me</th>
          <th>OP</th>
        </tr>
      </thead>
      <tbody>
        <EachScore myScores={props.myScores} updateScore={props.updateScore} remainRollCount={props.remainRollCount}/>
      </tbody>
    </table>
  );
}

function MakeRow(props) {
  const rows = [];
  for (let i = 0; i < props.dices.length; ++i) {
    if (props.dices[i].num === 0 || props.remainTurn === 0) {
      rows.push('0 ');
    } else {
      rows.push(<button key={i} onClick={() => props.onClick(i)}>
        {props.dices[i].num + '\n' + (props.dices[i].hold ? 'hold' : '')}
      </button>);
    }
  }
  return rows;
}

function Game(props) {
  const dices = [];
  for (let di of dicePool) {
    // di.num = Math.floor(Math.random() * 6 + 1);
    dices.push(di);
  }

  const [currentDices, setDices] = useState(dices);
  const [rollCount, setCount] = useState(maxRollCount);

  // 주사위 굴리기 보조 함수
  function diceRoll() {
    const diceArr = [];
    for (let i = 0; i < currentDices.length; ++i) {
      if (currentDices[i].hold) {
        diceArr.push({ num: currentDices[i].num, hold: true });
      } else {
        diceArr.push({ num: Math.floor(Math.random() * 6 + 1), hold: false });
      }
    }
    setDices(diceArr);
    return diceArr;
  }

  // 주사위 굴리기
  const [diceText, setDiceText] = useState(rollText);
  function rollDices() {
    if (rollCount === 0 || remainTurn === 0) {
      return;
    } else {
      setCount(rollCount - 1);
      if (rollCount === 1) {  // 이부분 이해가 안감
        setDiceText(cantRollText);
      }
      const rolled = diceRoll();
      showScores(rolled);
    }
  }

  // 주사위 굴린 뒤 점수 랜더링 및 점수 선택 이후 랜더링
  const [currentScore, setShowScore] = useState(scoreBoard);
  function showScores(rolled) {
    setShowScore(checkCategory(rolled, currentScore));
  }

  // 점수 선택이후, storedScores를 socket통신하면 될듯(혹은 삭제)
  const [storedScores, setStoredScores] = useState(scoreBoard);
  const [remainTurn, setRemainTurn] = useState(maxTurn);
  function updateScore(id) {
    const arr = [];
    currentScore[id].applied = true;
    for (let si of currentScore) {
      if (si.applied === false) {
        si.value = 0;
        arr.push(si);
      } else {
        arr.push(si);
      }
    }

    // 보너스 및 합계 계산
    calculateScores(arr);

    setRemainTurn(remainTurn - 1);
    setStoredScores(arr);
    setShowScore(arr);
    setDices(dicePool);
    setCount(maxRollCount);
    if (remainTurn === 1) {
      setDiceText(cantRollText);
    } else {
      setDiceText(rollText);
    }
    
  }

  // 주사위 홀드
  function holdNumber(i) {
    if (rollCount === maxRollCount) return;
    const tempDices = [];
    let h;
    for (let j = 0; j < currentDices.length; ++j) {
      let n = currentDices[j].num;
      if (j === i) {
        h = !currentDices[j].hold;
      } else {
        h = currentDices[j].hold;
      }

      tempDices.push({ num: n, hold: h });
    }

    setDices(tempDices);
  }

  function resetStored() {
    resetScoreAndDices();
    setDices(dicePool);
    setCount(maxRollCount);
    setDiceText(rollText);
  }

  return (
    <div>
      <div><MakeRow dices={currentDices} remainTurn={remainTurn} onClick={i => holdNumber(i)} /></div>
      <div>Remain roll count : {rollCount}</div>
      <div>Remain Turn : {remainTurn}</div>
      <div><RollButton onClick={rollDices} diceText={diceText} remainRollCount={rollCount}/></div>
      <div><Table myScores={currentScore} updateScore={updateScore} remainRollCount={rollCount}/></div>
    </div>);
}

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);

function resetScoreAndDices() {
  for (let di of dicePool) {
    di.num = 0;
    di.hold = false;
  }

  for (let si of scoreBoard) {
    si.value = 0;
    si.applied = false;
  }
}

function checkCategory(dices, currentScore) {
  // 주사위 점수 체크
  const arr = [];
  // dices 복사본 만들기
  const copied = (() => {
    const temp = [];
    for (let di of dices){
      temp.push(di);
    }
    return temp;
  })();

  copied.sort((a, b) => { return a.num - b.num });
  const diceMap = (() => {
    const each = [{ d: 1, v: 0 }, { d: 2, v: 0 }, { d: 3, v: 0 }, { d: 4, v: 0 }, { d: 5, v: 0 }, { d: 6, v: 0 }];
    for (let di of copied) {
      ++each[di.num - 1].v;
    }

    return each;
  })();

  for (let ci of currentScore) {
    if (ci.applied || ci.id === 'total' || ci.id === 'bonus') {
      arr.push(ci);
    } else {
      switch (ci.id) {
        case 'ones':
          ci.value = diceMap[0].v * 1;
          break;
        case 'twos':
          ci.value = diceMap[1].v * 2;
          break;
        case 'threes':
          ci.value = diceMap[2].v * 3;
          break;
        case 'fours':
          ci.value = diceMap[3].v * 4;
          break;
        case 'fives':
          ci.value = diceMap[4].v * 5;
          break;
        case 'sixes':
          ci.value = diceMap[5].v * 6;
          break;
        case 'choice':
          ci.value = copied.reduce((acc, val) => (acc + val.num), 0);
          break;
        case 'fourOfAKind':
          // ci.value = diceMap.reduce((acc, val) => (acc + (val.v >= 4 ? val.d : 0)), 0);
          ci.value = (() => {
            for (let si of diceMap) {
              if (si.v >= 4)
                return copied.reduce((acc, val) => (acc + val.num), 0);
            }

            return 0;
          })();
          break;
        case 'fullHouse':
          ci.value = (() => {
            let a = 0, b = 0;
            for (let si of diceMap) {
              if (si.v === 2)
                a = si.d * 2;
              if (si.v === 3)
                b = si.d * 3;
            }

            return a !== 0 && b !== 0 ? a + b : 0;
          })();
          break;
        case 'littleStraight':
          ci.value = (() => {
            for (let i = 0; i < copied.length; ++i) {
              if (copied[i].num !== i + 1)
                return 0;
            }

            return 30;
          })();
          break;
        case 'bigStraight':
          ci.value = (() => {
            for (let i = 0; i < copied.length; ++i) {
              if (copied[i].num !== i + 2)
                return 0;
            }

            return 30;
          })();
          break;
        case 'yacht':
          ci.value = (() => {
            for (let i = 0; i < copied.length; ++i) {
              if (copied[i].num !== copied[0].num) {
                return 0;
              }
            }

            return 50;
          })();
          break;
        default:
          break;
      }

      arr.push(ci);
    }
  }

  return arr;
}

function calculateScores(scores) {
  scores[scores.findIndex(x => x.id === 'bonus')].value = (() => {
    let t = 0;
    for (let si of scores) {
      if (si.id === 'ones' || si.id === 'twos' || si.id === 'threes' || si.id === 'fours' || si.id === 'fives' || si.id === 'sixes') {
        t += si.value;
      }
    }

    return t;
  })() >= bonusLimit ? bonusScore : 0;

  scores[scores.findIndex(x => x.id === 'total')].value = (() => {
    let t = 0;
    for (let si of scores) {
      if (si.id !== 'total')
        t += si.value;
    }

    return t;
  })();
}